/*--------------------------------------------------------------
  # Scroll top button
  --------------------------------------------------------------*/
  .scroll-top {
    position: fixed;
    visibility: visible;
    opacity: 0;
    right: 15px;
    bottom: -15px;
    z-index: 999999;
    background: var(--color-primary);
    width: 44px;
    height: 44px;
    border-radius: 50px;
    transition: all 0.4s;
  }
  
  .scroll-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
  }
  
  .scroll-top:hover {
    background: var(--color-tertiary);
    color: #fff;
  }
  
  .scroll-top.active {
    visibility: visible;
    opacity: 1;
    bottom: 15px;
  }