.faq {
    background-color: #f6f6f6;
  }
  
  .faq .content h3 {
    font-weight: 400;
    font-size: 34px;
  }
  
  .faq .content h4 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
  }
  
  .faq .content p {
    font-size: 15px;
    color: #6c757d;
  }
  
  .faq .accordion-item {
    border: 0;
    margin-bottom: 20px;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
  }
  
  .faq .accordion-item:last-child {
    margin-bottom: 0;
  }
  
  .faq .accordion-collapse {
    border: 0;
  }
  
  .faq .accordion-button {
    padding: 20px 50px 20px 20px;
    font-weight: 600;
    border: 0;
    font-size: 18px;
    line-height: 24px;
    color: var(--color-default);
    text-align: left;
    background: #fff;
    box-shadow: none;
    border-radius: 10px;
  }
  
  .faq .accordion-button .num {
    padding-right: 10px;
    font-size: 20px;
    line-height: 0;
    color: var(--color-primary);
  }
  
  .faq .accordion-button:not(.collapsed) {
    color: var(--color-primary);
    border-bottom: 0;
    box-shadow: none;
  }
  
  .faq .accordion-button:after {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  
  .faq .accordion-body {
    padding: 0 40px 30px 45px;
    border: 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: none;
  }