/*--------------------------------------------------------------
  # About Us Section
  --------------------------------------------------------------*/
  .about h3 {
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 20px;
    font-family: var(--font-secondary);
  }
  
  .about .call-us {
    left: 10%;
    right: 10%;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
    padding: 20px;
    text-align: center;
  }
  
  .about .call-us h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  
  .about .call-us p {
    font-size: 28px;
    font-weight: 700;
    color: var(--color-primary);
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding: 0 0 10px 30px;
    position: relative;
  }
  
  .about .content ul i {
    position: absolute;
    font-size: 20px;
    left: 0;
    top: -3px;
    color: var(--color-primary);
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }

  .border-rounded{
    border-radius: 15px;;
  }