.link-banner {
  color: #dddddd;
}
.link-banner-active{
  color: #fff;
}

.banner-area {
    background: url(https://i.ibb.co/FVRHJ4L/common-banner.jpg) center no-repeat;
    background-size: cover;
    position: relative; }
    @media (max-width: 768px) {
      .banner-area .fullscreen {
        height: 650px !important; } }
    .banner-area .active-banner-slider .owl-nav {
      position: absolute;
      right: 0;
      bottom: -200px; }
      @media (max-width: 1920px) {
        .banner-area .active-banner-slider .owl-nav {
          right: -115px; } }
      @media (max-width: 1680px) {
        .banner-area .active-banner-slider .owl-nav {
          right: 0px; } }
      @media (max-width: 991px) {
        .banner-area .active-banner-slider .owl-nav {
          display: none; } }
      .banner-area .active-banner-slider .owl-nav .owl-prev,
      .banner-area .active-banner-slider .owl-nav .owl-next {
        display: inline-block;
        margin: 10px;
        opacity: .5;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s; }
        .banner-area .active-banner-slider .owl-nav .owl-prev:hover,
        .banner-area .active-banner-slider .owl-nav .owl-next:hover {
          opacity: 1; }
    .banner-area .banner-content {
      padding-left: 10px; }
      @media (max-width: 991px) {
        .banner-area .banner-content {
          padding-left: 0; } }
      .banner-area .banner-content h1 {
        font-size: 60px;
        line-height: 66px;
        font-weight: 700;
        margin-bottom: 30px; }
        @media (max-width: 767px) {
          .banner-area .banner-content h1 {
            font-size: 35px;
            line-height: 46px; } }
      .banner-area .banner-content p {
        margin: 0; }
    @media (max-width: 991px) {
      .banner-area .banner-img {
        display: none; } }
  
  .add-bag {
    margin-top: 30px; }
    .add-bag .add-btn {
      height: 50px;
      width: 50px;
      line-height: 52px;
      text-align: center;
      border-radius: 50%;
      box-shadow: 0 10px 20px rgba(255, 108, 0, 0.2); }
      .add-bag .add-btn span {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        display: inline-block;
        color: #fff; }
    .add-bag .add-text {
      padding-left: 15px;
      font-size: 12px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      color: #222222; }
  
  .organic-breadcrumb {
    background: url(https://i.ibb.co/FVRHJ4L/common-banner.jpg) center no-repeat;
    background-size: cover;
    padding-top: 130px; }
    @media (max-width: 991px) {
      .organic-breadcrumb {
        padding-top: 80px; } }
    @media (max-width: 767px) {
      .organic-breadcrumb {
        background: none;
        background: -webkit-linear-gradient(90deg, var(--color-tertiary) 0%, var(--color-primary) 100%);
        background: -moz-linear-gradient(90deg, var(--color-tertiary) 0%,var(--color-primary) 100%);
        background: -o-linear-gradient(90deg, var(--color-tertiary) 0%, var(--color-primary) 100%);
        background: linear-gradient(90deg, var(--color-tertiary) 0%, var(--color-primary) 100%); } }
  
  #category .organic-breadcrumb {
    margin-bottom: 100px; }
  
  .breadcrumb-banner {
    padding: 90px 0 100px 0; }
    .breadcrumb-banner h1 {
      font-weight: 600;
      color: #fff; }
      @media (max-width: 767px) {
        .breadcrumb-banner h1 {
          font-size: 30px; } }
    .breadcrumb-banner p {
      margin-bottom: 0;
      color: #fff; }
    .breadcrumb-banner nav a {
      display: inline-block;
      color: #fff;
      font-weight: 400; }
      .breadcrumb-banner nav a span {
        display: inline-block;
        margin: 0 10px; }
    .breadcrumb-banner .col-first {
      padding-right: 15px; }
      @media (max-width: 1680px) {
        .breadcrumb-banner .col-first {
          padding-right: 40px; } }
      @media (max-width: 991px) {
        .breadcrumb-banner .col-first {
          width: 48%; } }
      @media (max-width: 767px) {
        .breadcrumb-banner .col-first {
          width: 100%; } }
  
  .p1-gradient-bg, .details-tab-navigation .nav-link:hover, .details-tab-navigation .nav-link.active, .quick-view-carousel-details .owl-controls .owl-dots .owl-dot.active:after, .organic-body .quick-view-carousel .owl-controls .owl-dots .owl-dot.active:after, .organic-body .organic, .organic-body .organic:hover, .checkput-login .top-title, .register-form, .mini-cart-2a.mini-cart-1 .mini-border, .mini-cart-2a.mini-cart-2 .mini-border, .mini-cart-2a.mini-cart-3 .mini-border, .mini-cart-2a.mini-cart-4 .mini-border, .mini-cart.mini-cart-1 .mini-border, .mini-cart.mini-cart-2 .mini-border, .mini-cart.mini-cart-3 .mini-border, .mini-cart.mini-cart-4 .mini-border, .item-cart, .submit-btn, .submit-btn.color-1, .submit-btn.color-2, .submit-btn.color-3, .submit-btn.color-4, .view-btn.color-1:after, .view-btn.color-2:after, .view-btn.color-3:after, .view-btn.color-4:after, .organic-section-title-left .carousel-trigger .prev-trigger:after, .organic-section-title-left .carousel-trigger .next-trigger:after, .single-search-product:before, .single-organic-product:after, .single-organic-product .bottom, .single-organic-product:hover .discount, .single-furniture-product .bottom, .single-furniture-product:hover .discount, .single-sidebar-product:before, .single-organic-product-list:after {
    background-image: -moz-linear-gradient(45deg, #f6463d 0%, #f6398d 45%, #f52cdc 100%);
    background-image: -webkit-linear-gradient(45deg, #f6463d 0%, #f6398d 45%, #f52cdc 100%);
    background-image: -ms-linear-gradient(45deg, #f6463d 0%, #f6398d 45%, #f52cdc 100%); }
  
  .p1-gradient-color, .organic-body .quick-view-content .price span, .organic-body .quick-view-content .category span, .organic-body .quick-view-content .view-full, .organic-product-top .single-product-top:hover .product-title, .tab-navigation .nav-link:hover, .tab-navigation .nav-link.active, .mini-cart-2a.mini-cart-1 .middle h5 a:hover, .mini-cart-2a.mini-cart-1 .cross span:hover, .mini-cart-2a.mini-cart-2 .middle h5 a:hover, .mini-cart-2a.mini-cart-2 .cross span:hover, .mini-cart-2a.mini-cart-3 .middle h5 a:hover, .mini-cart-2a.mini-cart-3 .cross span:hover, .mini-cart-2a.mini-cart-4 .middle h5 a:hover, .mini-cart-2a.mini-cart-4 .cross span:hover, .mini-cart.mini-cart-1 .middle h5 a:hover, .mini-cart.mini-cart-1 .cross span:hover, .mini-cart.mini-cart-2 .middle h5 a:hover, .mini-cart.mini-cart-2 .cross span:hover, .mini-cart.mini-cart-3 .middle h5 a:hover, .mini-cart.mini-cart-3 .cross span:hover, .mini-cart.mini-cart-4 .middle h5 a:hover, .mini-cart.mini-cart-4 .cross span:hover, .single-search-product .desc .title:hover, .single-sidebar-product .desc .title:hover {
    background: -moz-linear-gradient(45deg, #f6463d 0%, #f6398d 45%, #f52cdc 100%);
    background: -webkit-linear-gradient(45deg, #f6463d 0%, #f6398d 45%, #f52cdc 100%);
    background: -ms-linear-gradient(45deg, #f6463d 0%, #f6398d 45%, #f52cdc 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }