/*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
  
    
  .contact .info-container {
    background-color: var(--color-primary);
    height: 100%;
    padding: 20px;
    border-radius: 10px 0 0 10px;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  }
  
  .contact .info-item {
    width: 100%;
    background-color: var(--color-tertiary);
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    color: #fff;
  }
  
  .contact .info-item:last-child {
    margin-bottom: 0;
  }
  
  .contact .info-item i {
    font-size: 20px;
    color: #fff;
    float: left;
    width: 44px;
    height: 44px;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    margin-right: 15px;
  }
  
  .contact .info-item h4 {
    padding: 0;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .contact .info-item p {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
  }
  
  .contact .info-item:hover i {
    background: #fff;
    color: var(--color-primary);
  }
  
  .contact .php-email-form {
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 0 10px 10px 0;
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #df1529;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br+br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #059652;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #059652;
    border-top-color: #fff;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
  }
  
  .contact .php-email-form input[type=text],
  .contact .php-email-form input[type=email],
  .contact .php-email-form textarea {
    padding: 12px 15px;
  }
  
  .contact .php-email-form input[type=text]:focus,
  .contact .php-email-form input[type=email]:focus,
  .contact .php-email-form textarea:focus {
    border-color: var(--color-primary);
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type=submit] {
    background: var(--color-primary);
    border: 0;
    padding: 14px 45px;
    color: #f3f3f3;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: var(--color-tertiary);
    color: #fff;
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }

  .contact .text-center button {
    padding: 10px 25px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .contact .text-center button.sending, .contact .text-center button.sent
  {
  background-color: #198754;
  }
  
  .contact .text-center button.sending .sending-text {
  margin-right: 5px;
  }
  
  .contact .text-center button:disabled {
  cursor: not-allowed;
  }
  
  .contact .text-center button span,
  .contact .text-center button i {
  vertical-align: middle;
  }
  
  .contact .text-center button i {
  margin-left: 5px;
  }

