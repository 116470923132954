@media (max-width: 992px){
    .product-price{
        font-size: 20px;
    }
}
@media (min-width: 992px){
    .product-price{
        font-size: 28px;
    }
}

@media (max-width: 768px){
    .img-product-detail{
        width: 100%;
    }
}
@media (min-width: 768px){
    .img-product-detail{
        width: 80%;
    }
}


.title-product {
    color: var(--color-primary);
}

.items-product {
    color: #696969;
}

.code {
    color: #1da0f8;
}

.product-price{
    color: var(--color-primary);
    /* font-size: 30px; */
}

.product-stock{
    color: #696969;
    font-size: 12px;
}

.alert-buy-product{
    background-color:#183dc291 ;
    color: white;
    padding: 5px;
    border: 1px solid var(--color-primary);
}

.button-addToCart{
    background-color: var(--color-tertiary);
    color: white;
}

.button-addToCart:hover{
    background-color: var(--color-primary);
    color: var(--color-secondary);
}

