.topbar {
    background: var(--color-tertiary);
    height: 40px;
    font-size: 14px;
    transition: all 0.5s;
    color: #fff;
    padding: 0;
  }
  
  .topbar .contact-info i {
    font-style: normal;
    color: #fff;
    line-height: 0;
  }
  
  .topbar .contact-info i a,
  .topbar .contact-info i span {
    padding-left: 5px;
    color: #fff;
  }
  
  @media (max-width: 575px) {
  
    .topbar .contact-info i a,
    .topbar .contact-info i span {
      font-size: 13px;
    }
  }
  
  .topbar .contact-info i a {
    line-height: 0;
    transition: 0.3s;
  }
  
  .topbar .contact-info i a:hover {
    color: #fff;
    text-decoration: underline;
  }
  
  .topbar .social-links a {
    color: rgba(255, 255, 255, 0.7);
    line-height: 0;
    transition: 0.3s;
    margin-left: 20px;
  }

  .info-shipping-topbar{
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .topbar .social-links a:hover {
    color: #fff;
    
  }